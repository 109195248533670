import store from '@/store';
import axios from '@/services/api/Common';
import { AxiosPromise } from 'axios';

export default class MetricApi {
    private static host: string = store.getters['siteConfigState/getBackend'];

    private static apiClient: any = axios.create({
        withCredentials: true,
    });

    private static config: any = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    public static getMetrics(route: string, days: number = 60): AxiosPromise {
        return this.apiClient.get(
            `${this.host}/api/v2/downloads/metrics/${route}?days=${days}`,
            this.config,
        );
    }
}
