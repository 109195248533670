



























import Vue from 'vue';
import { TABS } from '@/models/MetricModel';
import MetricApi from '@/services/api/MetricApi';
import { Component } from 'vue-property-decorator';
import MetricTable from '@/components/DownloadMetrics/MetricTable.vue';
import QuantumReloadData from '@quantum/common-vue-components/src/components/ReloadData/ReloadData.vue';

@Component({
    components: {
        MetricTable,
        QuantumReloadData,
    },
})
export default class DownloadMetricList extends Vue {
    public tab: number = 0;

    public tabs: Record<string, string>[] = TABS;

    public items: Record<string, any>[] = [];

    public loading: boolean = false;

    public activeTab: string = '';

    public daysFilter: number = 0;

    public mounted(): void {
        if (this.isAdmin || this.isLicenseAdmin) {
            this.setActiveTab();

            this.setDaysFilter();

            this.getItems();

            return;
        }

        this.$router.push({ name: 'licensing-report' });
    }

    public setDaysFilter(): void {
        const filters = localStorage.getItem(this.activeTab);

        if (filters) {
            this.daysFilter = JSON.parse(filters).days || 60;

            return;
        }

        this.daysFilter = 60;
    }

    public filterByDays(days: number): void {
        if (this.daysFilter !== days) {
            this.daysFilter = days;

            this.getItems();
        }
    }

    public get isAdmin(): boolean {
        return this.$store.getters['profile/isAdmin'];
    }

    public get isLicenseAdmin(): boolean {
        return this.$store.getters['profile/isLicenseAdmin'];
    }

    public onTabChanged(): void {
        this.items = [];

        this.setActiveTab();

        this.setDaysFilter();

        this.getItems();
    }

    public setActiveTab(): void {
        const { key } = this.tabs[this.tab];

        this.activeTab = key;
    }

    public async getItems(): Promise<void> {
        this.loading = true;

        const route: string = this.generateMetricRoute();

        try {
            const response = await MetricApi.getMetrics(route, this.daysFilter);
            const { data: { data } } = response;

            this.items = data;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    }

    public generateMetricRoute(): string {
        let route: string = '';

        switch (this.activeTab) {
            case 'users':
                route = 'users';

                break;
            case 'files':
                route = 'files';

                break;
            case 'user_files':
                route = 'users/files';

                break;
            case 'os':
                route = 'clients/os';

                break;
            case 'version':
                route = 'clients/version';

                break;
            case 'clients':
                route = 'clients/files';

                break;
        }

        return route;
    }
}
