export const TABS = [
    {
        title: 'Users',
        key: 'users',
    },
    {
        title: 'Files',
        key: 'files',
    },
    {
        title: 'User/Files',
        key: 'user_files',
    },
    {
        title: 'Client OS',
        key: 'os',
    },
    {
        title: 'Client StorNext Version',
        key: 'version',
    },
    {
        title: 'Clients',
        key: 'clients',
    },
];

export const HEADERS = {
    users: [
        { text: 'Company', value: 'companyName' },
        { text: 'User', value: 'email' },
    ],
    files: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'File Name', value: 'fileName' },
        { text: 'Version', value: 'version' },
    ],
    user_files: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'User', value: 'email' },
        { text: 'Company', value: 'companyName' },
        { text: 'File Name', value: 'fileName' },
        { text: 'Version', value: 'version' },
    ],
    os: [
        { text: 'Client OS', value: 'operatingSystem' },
        { text: 'OS Version', value: 'operatingSystemVersion' },
    ],
    version: [
        { text: 'Clients By StorNext Version', value: 'version' },
    ],
    clients: [
        { text: 'Description', value: 'description' },
        { text: 'File Name', value: 'fileName' },
        { text: 'StorNext Version', value: 'version' },
    ],
};

export const DAYS_FILTER = [7, 30, 60, 90, 180, 365];
