

































































































import Vue from 'vue';
import { DataTableHeader } from 'vuetify';
import { DAYS_FILTER, HEADERS } from '@/models/MetricModel';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class MetricTable extends Vue {
    @Prop({ required: true, type: String })
    public tabKey!: string;

    @Prop({ required: true, type: Array })
    public items!: Record<string, string>[];

    @Prop({ required: true, type: Boolean })
    public loading!: boolean;

    public days: number[] = DAYS_FILTER;

    public filters: Record<string, string | number | null> = {
        search: '',
        days: 60,
        name: '',
        company: '',
        version: '',
        os: '',
        osVersion: '',
    };

    @Watch('tabKey', { immediate: true })
    public onTabChanged(): void {
        const filters = localStorage.getItem(this.tabKey);

        if (filters) {
            this.filters = JSON.parse(filters);
        }
    }

    @Watch('filters', { deep: true })
    public onFiltersChanged(): void {
        localStorage.setItem(this.tabKey, JSON.stringify(this.filters));

        this.$emit('filterByDays', this.filters.days);
    }

    public isFilterActive(filter: string | string[]) {
        if (Array.isArray(filter)) {
            return filter.includes(this.tabKey);
        }

        return this.tabKey === filter;
    }

    public get companies(): string[] {
        return [...new Set(this.items.map(item => item.companyName))].filter(item => item);
    }

    public get names(): string[] {
        return [...new Set(this.items.map(item => item.name))].filter(item => item);
    }

    public get versions(): string[] {
        const key: string = this.tabKey === 'os' ? 'operatingSystemVersion' : 'version';

        return [...new Set(this.items.map(item => item[key]))].filter(item => item);
    }

    public get systems(): string[] {
        return [...new Set(this.items.map(item => item.operatingSystem))].filter(item => item);
    }

    public getVersionLabel(): string {
        return ['version', 'clients'].includes(this.tabKey) ? 'StorNext Version' : 'Version';
    }

    public get headers(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
            { text: 'Downloads', value: 'downloadsCount' },
        ];
        // eslint-disable-next-line
        const tabHeaders = <{ [key: string]: DataTableHeader[] }> HEADERS;

        return [ ...headers, ...tabHeaders[this.tabKey]];
    }

    public getFilteredItems(): Record<string, any>[] {
        let items = this.items;

        if (this.filters.company) {
            items = items.filter(item => item.companyName === this.filters.company);
        }

        if (this.filters.name) {
            items = items.filter(item => item.name === this.filters.name);
        }

        if (this.filters.version) {
            items = items.filter(item => item.version === this.filters.version);
        }

        if (this.filters.os) {
            items = items.filter(item => item.operatingSystem === this.filters.os);
        }

        if (this.filters.osVersion) {
            items = items.filter(item => item.operatingSystemVersion === this.filters.osVersion);
        }

        return items;
    }
}
